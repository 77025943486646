/* eslint-disable no-param-reassign */
import { getNormalizedScrollLeft, setNormalizedScrollLeft } from 'normalize-scroll-left';
import { debounce } from 'debounce';

const dragScrollClass = 'dragscroll';

export const destroyDragScroll = el => {
	el.removeEventListener('mousedown', el.md, 0);
	window.removeEventListener('mouseup', el.mu, 0);
	window.removeEventListener('click', el.cl, 0);
	window.removeEventListener('mousemove', el.mm, 0);

	if (!el.ts) {
		return;
	}

	el.removeEventListener('touchstart', el.ts, 0);
	window.removeEventListener('touchmove', el.tm, 0);
	window.removeEventListener('touchend', el.te, 0);
	window.removeEventListener('touchcancel', el.tc, 0);
};

const directionCache = new Map();

const getDocDir = elm => {
	// caching to avoid quering getComputedStyle which is expensive and might cause a reflow
	if (!directionCache.has(elm)) {
		directionCache.set(elm, window.getComputedStyle(elm).direction);
	}
	return directionCache.get(elm);
};

export const getScrollLeft = (element, docDir = 'ltr') =>
	docDir === 'rtl' ? getNormalizedScrollLeft(element, docDir) : element.scrollLeft;

const setScrollLeft = (element, val, docDir = 'ltr') => {
	if (docDir === 'rtl') {
		setNormalizedScrollLeft(element, val, docDir);
		return;
	}
	element.scrollLeft = val;
};

export const scrollLeftTo = (element, endValue, duration = 250) => {
	const docDir = getDocDir(element);
	const startValue = getScrollLeft(element, docDir);
	const delta = endValue - startValue;
	const start = window.performance.now();
	const step = timestamp => {
		const progress = timestamp - start;
		if (progress < duration) {
			// Cosine based ease-in-out time transition-timing-function
			const diff = Math.round(((Math.cos(Math.PI * (1 + progress / duration)) + 1) / 2) * delta);
			setScrollLeft(element, startValue + diff, docDir);
			window.requestAnimationFrame(step);
		} else {
			setScrollLeft(element, endValue, docDir);
		}
	};
	window.requestAnimationFrame(step);
};

const toggleCanScroll = (dragElm, container) => {
	const classLeft = 'can-scroll-left';
	const classRight = 'can-scroll-right';
	const scrollLeft = getScrollLeft(dragElm);
	const { clientWidth, scrollWidth } = dragElm;
	if (clientWidth < scrollWidth) {
		// left button conditional rendering
		if (scrollLeft > 0) {
			container.classList.add(classLeft);
		} else {
			container.classList.remove(classLeft);
		}
		// right button conditional rendering
		if (Math.ceil(scrollLeft + clientWidth) < scrollWidth) {
			container.classList.add(classRight);
		} else {
			container.classList.remove(classRight);
		}
	} else {
		container.classList.remove(classLeft, classRight);
	}
};

export const handleCanScroll = (dragElm, container) => {
	toggleCanScroll(dragElm, container);

	// Set up handler functions
	const debouncedToggleCanScroll = debounce(() => toggleCanScroll(dragElm, container), 150);
	window.addEventListener('resize', debouncedToggleCanScroll);
	dragElm.addEventListener('scroll', debouncedToggleCanScroll);
};

export const createDragScroll = (dragElm, { canScrollWrapper, enableTouch }) => {
	let lastClientX = 0;
	let lastClientY = 0;
	let pushed = false;

	// toggle can-scroll-left/can-scroll-right classes on chosen containers
	handleCanScroll(dragElm, canScrollWrapper);

	// Mouse down event handler
	dragElm.md = e => {
		// Add mouse move handler on mouse down
		window.addEventListener('mousemove', dragElm.mm, 0);

		setTimeout(() => {
			// Postpone this a bit to let the click event trigger first
			pushed = true;
			lastClientX = e.clientX;
			lastClientY = e.clientY;
		}, 150);

		e.preventDefault();
		e.stopPropagation();
	};

	// Mouse up event handler
	dragElm.mu = () => {
		// Remove mouse move handler on mouse up
		window.removeEventListener('mousemove', dragElm.mm, 0);

		setTimeout(() => {
			// Postpone this a bit to let the click event trigger first
			pushed = false;
		}, 150);
	};

	// Click event handler
	dragElm.cl = () => {
		// If clicked, mouse move event handler not needed, so remove
		window.removeEventListener('mousemove', dragElm.mm, 0);
	};

	// Mouse move event handler
	dragElm.mm = e => {
		if (pushed) {
			dragElm.scrollLeft -= -lastClientX + e.clientX;
			dragElm.scrollTop -= -lastClientY + e.clientY;
			lastClientX = e.clientX;
			lastClientY = e.clientY;
		}
	};

	// Add event listeners
	dragElm.addEventListener('mousedown', dragElm.md, 0);
	window.addEventListener('click', dragElm.cl, 0);
	window.addEventListener('mouseup', dragElm.mu, 0);

	if (!enableTouch) {
		return;
	}

	// Touch start event handler
	dragElm.ts = e => {
		pushed = true;
		lastClientX = e.touches[0].clientX;
		lastClientY = e.touches[0].clientY;
	};

	// Touch end event handler
	dragElm.te = () => {
		pushed = false;
	};

	// Touch move event handler
	dragElm.tm = e => {
		if (pushed) {
			dragElm.scrollLeft -= -lastClientX + e.touches[0].clientX;
			dragElm.scrollTop -= -lastClientY + e.touches[0].clientY;
			lastClientX = e.touches[0].clientX;
			lastClientY = e.touches[0].clientY;
		}
	};

	// Touch cancel event handler
	dragElm.tc = () => {
		pushed = false;
	};

	dragElm.addEventListener('touchstart', dragElm.ts, 0);
	window.addEventListener('touchend', dragElm.te, 0);
	window.addEventListener('touchcancel', dragElm.tc, 0);
	window.addEventListener('touchmove', dragElm.tm, 0);
};
/* eslint-enable */
export const dragScroll = el =>
	Array.from(el.getElementsByClassName(dragScrollClass)).forEach(element => {
		destroyDragScroll(element);
		createDragScroll(element, { enableTouch: true });
	});

export default dragScroll;
